import React, { useCallback, useContext } from 'react';
import * as S from './DelayButton.styles';
import axios from 'axios';
import { addDaysToDate } from '../../utils/addDaysToDate';
import FunnelContext from '../../context/FunnelContext';
import useAuth from '../../hooks/useAuth';
import { Builder } from '@builder.io/react';
import LoadingCover from '../LoadingCover/LoadingCover';
import { isTokenExpired } from '../../utils/isTokenExpired';
import { addDataLayer } from '../../utils/dataLayer';
import { replaceUrlState } from '../../utils/replaceUrlState';

const DelayButton = props => {
  const { label, daysAmount, delayLengthText } = props;
  const {
    subscriptionList,
    deliveryDate,
    setDeliveryDate,
    calendarDate,
    loadingCover,
    setLoadingCover
  } = useContext(FunnelContext);

  const { token, setToken, getToken } = useAuth();
  let date;
  let isCustomDelay = !!calendarDate;

  if (calendarDate) {
    date = new Date(calendarDate);
  } else if (deliveryDate) {
    date = new Date(deliveryDate);
  } else {
    date = new Date();
  }

  if (daysAmount) {
    date = addDaysToDate(date, daysAmount);
  }
  const payloadDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T')[0];

  const delay = useCallback(async () => {
    if (!payloadDate || !subscriptionList) return;

    await setLoadingCover(true);

    if (token && isTokenExpired(token)) {
      let results = await getToken();
      results?.token && (await setToken(results.token));
    }

    let delayLength;
    if (isCustomDelay) {
      let oldDate = new Date(deliveryDate);
      let difference = date.getTime() - oldDate.getTime();
      delayLength = Math.ceil(difference / (1000 * 3600 * 24));
      delayLength = `${delayLength}Day${delayLength === 1 ? '' : 's'}`;
    } else {
      delayLength = delayLengthText;
    }

    const requestArr = await subscriptionList.map(async item => {
      return axios
        .post(
          `${process.env.GATSBY_ENDPOINT_API_SITE}/api/subscription/${item.id}/next-billing-date`,
          { date: payloadDate },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .catch(e => {
          console.log(e);
        });
    });

    let results = await Promise.all(requestArr);

    if (!results.every(el => el.status === 200)) return;

    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    setDeliveryDate(date.toLocaleDateString('en-US', dateOptions));
    replaceUrlState(
      `${window.location.origin + window.location.pathname}?layer=success`
    );
    addDataLayer('magic_link_delay_subscription_successful_delay', {
      delay_length: delayLength
    });
    setLoadingCover(false);

    return results;
  }, [
    token,
    setToken,
    getToken,
    date,
    payloadDate,
    deliveryDate,
    setDeliveryDate,
    setLoadingCover,
    subscriptionList,
    delayLengthText,
    isCustomDelay
  ]);

  if (loadingCover && !Builder.isEditing) {
    return <LoadingCover />;
  }

  return (
    <>
      <S.Button onClick={() => delay()} disabled={!calendarDate && !daysAmount}>
        {label}
      </S.Button>
    </>
  );
};

export default DelayButton;
