import styled from 'styled-components';

export const Button = styled.div`
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding: 10px;
  color: #ffffff;
  cursor: pointer;
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
`;
